import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import Player from "../components/audioplayer"
import "./track.css"

export default function Track({ data }) {
	const track = data.markdownRemark
	const audioPath = data.file.publicURL
	var edition = track.frontmatter.edition
	var imgPath = ""
	switch (edition) {
		case "I & II":
			imgPath = "RealbookCover1.png"
			break
		case "III":
			imgPath = "RealbookCover3.png"
			break
		case "Klavier":
			imgPath = "KlaviernotenTitel.png"
			break
		default:
			imgPath = "RealbookCover.png"
	}

	return (
		<Layout>
			<div className="img-wrap">
				<Image relativePath={imgPath} />
			</div>
			<header className="track-titel">
				<h1>{track.frontmatter.title}</h1>
			</header>
			<div className="player-wrap">
				<Player audioPath={audioPath} />
			</div>
		</Layout>
	)
}

export const query = graphql`
  query($slug: String!, $audio: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        audio
        edition
      }
    }
    file(relativePath: { eq: $audio }) {
    	publicURL
    }
  }
`