import React from "react"
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const Player = (props) => (
	<AudioPlayer
		autoPlay
		src={props.audioPath}
		volume="0.8"
		showJumpControls={false}
		customVolumeControls={[]}
		customAdditionalControls={[]}
		customProgressBarSection={[ RHAP_UI.PROGRESS_BAR, ]}
	/>
)

export default Player